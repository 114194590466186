// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

// Eliminar una lista de precios
export const deletePriceList = async (id) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/priceList/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Obtener una lista de precios por su ID
export const getPriceListById = async (id) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/priceList/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Actualizar una lista de precios existente
export const savePriceList = async (priceList) => {
  try {
    const newPriceList = {
      code: priceList.code,
      description: priceList.description,
      slug: priceList.slug,
      stageId: priceList.stageId,
      balconyPrice: priceList.balconyPrice || '0',
      buildPrice: priceList.buildPrice || '0',
      mezzaninePrice: priceList.mezzaninePrice || '0',
      terracePrice: priceList.terracePrice || '0',
      floorIncrease: priceList.floorIncrease || '0',
      viewDelta: priceList.viewDelta || '0'
    }
    if (priceList.id) {
      newPriceList.id = priceList.id;
    }
    const response = await axiosInstance.put(`${API_URL}/priceList`, newPriceList);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
