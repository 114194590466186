// src/pages/SubsidyPlanForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getSubsidyPlanById, saveSubsidyPlan } from '../services/SubsidyPlanServices';
import './Forms.css';

function SubsidyPlanForm() {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subsidyPlan, setSubsidyPlan] = useState({ name: '', code: '', description: '', maxSubsidy: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchSubsidyPlan = async () => {
        try {
          const subsidyPlanData = await getSubsidyPlanById(id); // Usar el servicio para obtener plan de subsidio
          setSubsidyPlan(subsidyPlanData);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
    
      fetchSubsidyPlan();
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      await saveSubsidyPlan(subsidyPlan); 
      navigate('/config'); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="form-container">
      <h2>{id ? 'Editar Plan de Subsidio' : 'Nuevo Plan de Subsidio'}</h2>
      {error && (
      <div>
        <div className='error-message'>
          Hubo un error: {error}
        </div>
        <br/>
      </div>
      )}
      <form onSubmit={handleSubmit} className="subsidy-plan-form">
        <div className="form-group">
          <label htmlFor="subisy-plan-name">Nombre</label>
          <input
            type="text"
            id="subisy-plan-name"
            value={subsidyPlan.name}
            onChange={(e) => setSubsidyPlan({ ...subsidyPlan, name: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subisy-plan-code">Código</label>
          <input
            type="text"
            id="subisy-plan-code"
            value={subsidyPlan.code}
            onChange={(e) => setSubsidyPlan({ ...subsidyPlan, code: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subisy-plan-description">Descripción</label>
          <textarea
            id="subisy-plan-description"
            value={subsidyPlan.description}
            onChange={(e) => setSubsidyPlan({ ...subsidyPlan, description: e.target.value })}
            rows="5"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subisy-plan-maxSubsidy">Máximo subsidio (SMLV)</label>
          <input
            type="number"
            id="subisy-plan-maxSubsidy"
            value={subsidyPlan.maxSubsidy}
            onChange={(e) => setSubsidyPlan({ ...subsidyPlan, maxSubsidy: e.target.value })}
          />
        </div>

        <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Plan de Subsidio'}</button>
      </form>
      <div className="form-footer">
        <Link to="/config" className="back-link">Regresar a datos básicos</Link>
      </div>
    </div>
  );
}

export default SubsidyPlanForm;
