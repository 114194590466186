import React, { useEffect } from 'react';
import { getLeads } from '../services/LeadServices';
import { getQuotes } from '../services/QuoteServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const ProjectQuotes = ({ project, units, unit }) => {
    const [activeTab, setActiveTab] = React.useState('leads');
    const [leads, setLeads] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [quotes, setQuotes] = React.useState([]);
    const [quotesAll, setQuotesAll] = React.useState([]);
    const [selectedQuote, setSelectedQuote] = React.useState(null);
  
    const formatDate = (date) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Date(date).toLocaleDateString('es-ES', options).replace(/ de /g, '-').toUpperCase();
    }

    const formatValue = (value) => {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }

    const handleRefresh = async () => {
        await readQuotes();
    }

    const handleRowClick = (q) => {
        setSelectedQuote(q);
        setActiveTab('data');
    };

    const readQuotes = React.useCallback(async () => {
        const filterQuotes = (quotes) => {
            return quotes.filter((quote) => {
                const unit = units.find((unit) => unit.id === quote.unitId);
                if (unit) {
                    quote.unitCode = unit.code;
                    return true;
                }
                return false;
            });
        }
    
        const getUnit = (quote) => {
            const unit = units.find((u) => u.id === quote.unitId);
            return unit ? unit.code : 'N/E';
        }
        
        const list = await getQuotes(project.id);
        for (let q of list) {
            q.unitCode = getUnit(q);
        }
        list.sort((a, b) => {
            const unitCodeA = a.unitCode.toUpperCase();
            const unitCodeB = b.unitCode.toUpperCase();
            if (unitCodeA < unitCodeB) {
                return -1;
            }
            if (unitCodeA > unitCodeB) {
                return 1;
            }
            const leadNameA = a.leadName.toUpperCase();
            const leadNameB = b.leadName.toUpperCase();
            if (leadNameA < leadNameB) {
                return -1;
            }
            if (leadNameA > leadNameB) {
                return 1;
            }
            return 0;
        });
        const uniqueQuotes = list.reduce((acc, current) => {
            if (current.code.length === 8) {
                acc.push(current);
            }
            return acc;
        }, []);
        setQuotesAll(uniqueQuotes);
        const filtered = filterQuotes(uniqueQuotes);
        console.log(filtered);
        setQuotes(filtered);
    },[project.id,units]);
    
    const renderContent = () => {
        switch (activeTab) {
            case 'leads':
                return (
                    <div>
                        <div className="lead-card header">
                            <h5>Nombre</h5>
                            <h5>Teléfono</h5>
                            <h5>Email</h5>
                        </div>
                        {leads.map((lead) => (
                            <div className="lead-card" key={lead.id}>
                                <div>{lead.firstName+" "+lead.lastName}</div>
                                <div>{lead.phone}</div>
                                <div className="email">{lead.email}</div>
                            </div>
                        ))}
                    </div>
                );
            case 'quotes':
                return <div>
                    <div>
                        <button onClick={() => handleRefresh()}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </button>
                    </div>
                    <div className="quote-card header">
                        <h5>Lead</h5>
                        <h5>Unidad</h5>
                        <h5>Fecha</h5>
                        <h5>Valor</h5>
                        <h5>Vis.</h5>
                    </div>
                    {quotes.map((quote) => (
                        <div className="quote-card" key={quote.id} onClick={() => handleRowClick(quote)}>
                            <div>{quote.leadName}</div>
                            <div>{quote.unitCode}</div>
                            <div>{formatDate(quote.updatedAt)}</div>
                            <div>{formatValue(quote.total+quote.parkingSpacePrice+quote.utilityRoomPrice)}</div>
                            <div>{quote.queries}</div>
                        </div>
                    ))}
                </div>
            case 'data':
                return <>
                    {selectedQuote && (
                        <div>
                            <h2>Detalles de la Cotización</h2>
                            <p><strong>Unidad:</strong> {selectedQuote.unitCode}</p>
                            <p><strong>Versión:</strong> {selectedQuote.version}</p>
                            <p><strong>Lead:</strong> {selectedQuote.leadName}</p>
                            <p><strong>Teléfono:</strong> {selectedQuote.leadPhone}</p>
                            <p><strong>Email:</strong> {selectedQuote.leadEmail}</p>
                            <p><strong>Fecha:</strong> {formatDate(selectedQuote.updatedAt)}</p>
                            <p><strong>Precio:</strong> {formatValue(selectedQuote.price)}</p>
                            <p><strong>Descuento</strong> {formatValue(selectedQuote.price*selectedQuote.discountPercentage)}</p>
                            <p><strong>Estacionamiento</strong> {formatValue(selectedQuote.parkingSpacePrice)}</p>
                            <p><strong>Cuarto útil</strong> {formatValue(selectedQuote.utilityRoomPrice)}</p>
                            <p><strong>Valor total:</strong> {formatValue(selectedQuote.total+selectedQuote.parkingSpacePrice+selectedQuote.utilityRoomPrice)}</p>
                            <p><strong>Cuota inicial:</strong> {formatValue(selectedQuote.downPayment)}</p>
                            <p><strong>Reserva:</strong> {formatValue(selectedQuote.separationPayment)}</p>
                            <p><strong>{selectedQuote.downPaymentMonths} cuotas de</strong> {formatValue(selectedQuote.monthlyDownPayment)}</p>
                            <p><strong>Crédito:</strong> {formatValue(selectedQuote.financed)}</p>
                            <p><strong>Visitas:</strong> {selectedQuote.queries}</p>
                            {/* Agrega más detalles según sea necesario */}
                        </div>
                    )}
                </>;
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const leads = await getLeads(project.id);
                leads.sort((a, b) => {
                    const nameA = `${a.firstName} ${a.lastName}`.toUpperCase();
                    const nameB = `${b.firstName} ${b.lastName}`.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setLeads(leads);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchData();
    }, [project]);

    useEffect(() => {
        const fetchData = async () => {
            await readQuotes();
        };

        fetchData();
    }, [project.id,readQuotes,units]);

    useEffect(() => {
        const filterQuotes = () => {
            return quotesAll.filter((q) => {
                if (unit.id === q.unitId) {
                    q.unitCode = unit.code;
                    return true;
                }
                return false;
            });
        }

        setActiveTab('quotes');
        if (unit) {
            setQuotes(filterQuotes());
        }
    }, [quotesAll,unit]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div>
            <div className="tabs">
                <button 
                    className={activeTab === 'leads' ? 'active' : ''}
                    onClick={() => setActiveTab('leads')}
                >
                    Leads
                </button>
                <button 
                    className={activeTab === 'quotes' ? 'active' : ''}
                    onClick={() => setActiveTab('quotes')}
                >
                        Cotizaciones
                </button>
                <button 
                    className={activeTab === 'data' ? 'active' : ''}
                    onClick={() => setActiveTab('data')}
                >
                    Datos
                </button>
            </div>
            <div className="tab-content-2">
                {renderContent()}
            </div>
        </div>
    );
};

export default ProjectQuotes;