import React, { useEffect, useState } from 'react';

const ProjectUnits = ({ project, units, onUnitChange }) => {
    const [loading, setLoading] = useState(true);

    const formatValue = (value) => {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(false);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchData();
    }, [project,units]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    return (
        <div>
            <div className="type-card header">
                <h5>Unidad</h5>
                <h5>Área</h5>
                <h5>Piso</h5>
                <h5>Precio</h5>
                <h5>Estado</h5>
            </div>
            {units.map((u) => (
                <div className="type-card" key={u.id} onClick={() => {onUnitChange(u)}}>
                    <div>{u.code}</div>
                    <div>{u.buildArea+" "+project.measureUnit}</div>
                    <div>{u.floor}</div>
                    <div>{formatValue(u.price)}</div>
                    <div><span className={`status ${u.status}`}></span></div>
                </div>
            ))}
        </div>
    );
};

export default ProjectUnits;