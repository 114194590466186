// src/services/countryService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_CRM_URL;

export const syncStatus = async (projectId) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/sync-status/${projectId}`,{});
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const syncUnits = async (projectId) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/sync-units/${projectId}`,{});
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

