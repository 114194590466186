import React, { useEffect, useRef, useState } from "react";

const ProjectFilters = ({ project, onUnitsChange, units, unit }) => {
  const [activeTab, setActiveTab] = useState("stages");
  const [block, setBlock] = useState(null);
  const [iframe, setIframe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState(null);
  const stageRef = useRef(stage);
  const [typology, setTypology] = useState(null);

  const formatValue = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  }

  useEffect(() => {
    const ifr = document.getElementById("project-iframe");
    setIframe(ifr);
    setLoading(false);
  }, [project]);
  
  useEffect(() => {
    stageRef.current = stage;
  }, [stage]);

  useEffect(() => {
  }, [units]);

  useEffect(() => {
    if (unit)
      setActiveTab("unit");
  }, [unit]);

  const getUnitStatus = (currentStatus, currentTypology,unitList) => {
    let filtered = [];
    let nameStatusPairs = [];
    let targetFrame = currentTypology.targetFrame;
    unitList.forEach((u) => {
      let visible = true;
      if (currentStatus !== 'all' && u.status !== currentStatus)
        visible = false;
      if (currentTypology && u.typologySlug !== currentTypology.slug)
        visible = false;        
      const unitCode = u.code + "-" + currentTypology.code.charAt(0).toUpperCase() + currentTypology.code.slice(1);
      if (visible) {
        filtered.push(unitCode);
      }
      nameStatusPairs.push({ name: unitCode, status: u.status });
    });  
    return { filtered: filtered, nameStatusPairs: nameStatusPairs, targetFrame: targetFrame };
  }

  const handleBlockChange = (b) => {
    setBlock(b);
    setActiveTab("typologies");
  }

  const handleStageChange = (s) => {
    setStage(s);
    setActiveTab("blocks");
  }

  const handleSelectTypology = (t) => {
    setTypology(t);

    const list = [];
    block.units.forEach((u) => {
      if (u.typologySlug === t.slug) {
        list.push(u);
      }
    });

    let unitStatus = getUnitStatus("all", t,list);
    const message = {
      action: "toggleSvg",
      status: true,
      filtered: unitStatus.filtered,
      nameStatusPairs: unitStatus.nameStatusPairs,
      targetFrame: unitStatus.targetFrame,
    };
    iframe.contentWindow.postMessage(message, iframe.src);

    onUnitsChange(list);
  };

  const selectByStatus = (status) => {
    const list = [];
    block.units.forEach((u) => {
      if (u.typologySlug === typology.slug) {
        list.push(u);
      }
    });

    let unitStatus = getUnitStatus(status, typology,list);
    const message = {
      action: "toggleSvg",
      status: true,
      filtered: unitStatus.filtered,
      nameStatusPairs: unitStatus.nameStatusPairs,
      targetFrame: unitStatus.targetFrame,
    };
    iframe.contentWindow.postMessage(message, iframe.src);

    onUnitsChange(list);
  }

  const renderContent = () => {
    switch (activeTab) {
      case "stages":
        return <>
            <h3>Seleccione etapa</h3>
            {project.stages.map((s) => (
                <div key={s.id} className="card" onClick={() => handleStageChange(s)}>
                <h4>{s.name}</h4>
                </div>
            ))}
        </>;
      case "blocks":
        return <>
          {stage && (
            <>             
              <h3>Seleccione el bloque</h3>
              {stage.blocks.map((b) => (
                <div key={b.id} className="card" onClick={() => handleBlockChange(b)}>
                  <h4>{b.name}</h4>
                </div>
              ))}
            </>
          )}
          {!stage && <div>No ha seleccionado etapa</div>}          
        </>;
      case "typologies":
        return <>
          {block && (
            <>
              <div className="type-card header">
                  <h5>Tipología</h5>
                  <h5>Área Min</h5>
                  <h5>Área Máx</h5>
                  <h5>Unidades</h5>
                  <h5>Un. Disp.</h5>
              </div>
              {stage.typologies.map((t) => (
                <div 
                  key={t.id} 
                  className={`type-card ${typology && typology.id === t.id ? 'selected' : ''}`} 
                  onClick={() => handleSelectTypology(t)}
                >
                  <h5>{t.name}</h5>
                  <p>{t.buildAreaMin}</p>
                  <p>{t.buildAreaMax}</p>
                  <p>{t.totalUnits}</p>
                  <p>{t.totalAvailable}</p>
                </div>
              ))}
            </>
          )}
          {!block && <div>No ha seleccionado bloque</div>}
        </>;
      case "unit":
        let typologyCode = '';
        if (unit) {
            typologyCode = unit.typologySlug.split('-').pop();
        }
        return (
            <div>
                {unit ? (
                    <div>
                        <h2>
                            {unit.code + " " + typologyCode}
                            <span className={`status ${unit.status}`}></span>
                        </h2>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%' }}>Área de construcción:</td>
                                    <td>{unit.buildArea + " " + project.measureUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Área privada:</td>
                                    <td>{unit.privateArea + " " + project.measureUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Área de balcón:</td>
                                    <td>{unit.balconyArea + " " + project.measureUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Área de terraza:</td>
                                    <td>{unit.terraceArea + " " + project.measureUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Área privada de terraza:</td>
                                    <td>{unit.terracePrivateArea + " " + project.measureUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Área de mezzanine:</td>
                                    <td>{unit.mezzanineArea + " " + project.measureUnit}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Piso:</td>
                                    <td>{unit.floor}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Habitaciones:</td>
                                    <td>{unit.bedrooms}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Baños:</td>
                                    <td>{unit.bathrooms}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '60%' }}>Precio:</td>
                                    <td>{formatValue(unit.price)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <h2>No hay una unidad seleccionada</h2>
                )}
            </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <div className="tabs">
        <button
          className={activeTab === 'stages' ? 'active' : ''}
          onClick={() => setActiveTab('stages')}
        >
          Etapa
        </button>
        <button
          className={activeTab === 'blocks' ? 'active' : ''}
          onClick={() => setActiveTab('blocks')}
        >
          Bloque
        </button>
        <button
          className={activeTab === 'typologies' ? 'active' : ''}
          onClick={() => setActiveTab('typologies')}
        >
          Tipología
        </button>
        <button
          className={activeTab === 'unit' ? 'active' : ''}
          onClick={() => setActiveTab('unit')}
        >
          Unidad
        </button>
      </div>
      {block && (
        <div className="status-buttons">
          <button onClick={() => selectByStatus('all')}>Todos</button>
          <button className="status-available" onClick={() => selectByStatus('available')}>Disponible</button>
          <button className="status-reserved" onClick={() => selectByStatus('reserved')}>Reservado</button>
          <button className="status-optioned" onClick={() => selectByStatus('optioned')}>Opcionado</button>
          <button className="status-sold" onClick={() => selectByStatus('sold')}>Vendido</button>
        </div>
      )}
      <div className="tab-content-1">{renderContent()}</div>
    </div>
  );
};

export default ProjectFilters;
