// src/pages/PriceListForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getPriceListById, savePriceList } from '../services/PriceListServices'; 
import { getStageById } from '../services/StageServices';

import './Forms.css';
import { getProjectById } from '../services/ProjectServices';

function PriceListForm() {
  const { id, stageId } = useParams(); 
  const [priceList, setPriceList] = useState({
    code: '',
    description: '',
    buildPrice: '',
    balconyPrice: '',
    mezzaninePrice: '',
    terracePrice: '',
    floorIncrease: '',
    viewDelta: '',
    slug: ''
  });
  const [error, setError] = useState(null);
  const [projectId, setProjectId] = useState(''); 
  const [project, setProject] = useState(null);
  const [stageSlug, setStageSlug] = useState(''); 
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el slug de la etapa
        const stage = await getStageById(stageId);
        const pr = await getProjectById(stage.projectId);
        setStageSlug(stage.slug);
        setProjectId(stage.projectId);
        setProject(pr);

        // Si estamos editando una lista de precios, obtener sus datos
        if (id) {
          const priceListData = await getPriceListById(id);
          setPriceList(priceListData);
        } else {
          setPriceList((prevList) => ({ ...prevList, stageId: stageId }));
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, stageId]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    const codeSlug = newCode.toLowerCase().replace(/ /g, '-');
    const newSlug = `${stageSlug}-${codeSlug}`;
    setPriceList({ ...priceList, code: newCode, slug: newSlug });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (id)
        priceList.id = id;
      priceList.stageId = stageId;
      await savePriceList(priceList);
      navigate(`/stages/edit/${projectId}/${stageId}`);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar Lista de Precios' : 'Nueva Lista de Precios'}</h2>
        {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
        )}
        <form onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="priceList-code">Código de la lista</label>
            <input
              type="text"
              id="priceList-code"
              value={priceList.code}
              onChange={handleCodeChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="block-description">Descripción</label>
            <textarea
              value={priceList.description}
              id="priceList-description"
              onChange={(e) => setPriceList({ ...priceList, description: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-buildPrice">Precio de Construcción ({ project.measureUnit || '' })</label>
            <input
              type="number"
              id="priceList-buildPrice"
              value={priceList.buildPrice}
              onChange={(e) => setPriceList({ ...priceList, buildPrice: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-balconyPrice">Precio del Balcón  ({ project.measureUnit || '' })</label>
            <input
              type="number"
              id="priceList-balconyPrice"
              value={priceList.balconyPrice}
              onChange={(e) => setPriceList({ ...priceList, balconyPrice: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-mezzaninePrice">Precio del Mezzanine  ({ project.measureUnit || '' })</label>
            <input
              type="number"
              id="priceList-mezzaninePrice"
              value={priceList.mezzaninePrice}
              onChange={(e) => setPriceList({ ...priceList, mezzaninePrice: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-terracePrice">Precio de la Terraza  ({ project.measureUnit || '' })</label>
            <input
              type="number"
              id="priceList-terracePrice"
              value={priceList.terracePrice}
              onChange={(e) => setPriceList({ ...priceList, terracePrice: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-floorIncrease">Aumento por Piso ({ project.currency || '' } x {project.measureUnit || ''})</label>
            <input
              type="number"
              id="priceList-floorIncrease"
              value={priceList.floorIncrease}
              onChange={(e) => setPriceList({ ...priceList, floorIncrease: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-viewDelta">Delta de Vista ({ project.currency || '' } x {project.measureUnit || ''})</label>
            <input
              type="number"
              id="priceList-viewDelta"
              value={priceList.viewDelta}
              onChange={(e) => setPriceList({ ...priceList, viewDelta: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="priceList-slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="priceList-slug"
              value={priceList.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Lista de Precios'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to={`/stages/edit/${projectId}/${stageId}`} className="back-link">Regresar a Etapa</Link>
        </div>
      </div>
    </div>
  );
}

export default PriceListForm;
