// src/pages/BlockForm.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { deleteUnit } from '../services/UnitServices';
import { getBlockById, saveBlock } from '../services/BlockServices'; 
import { getStageById } from '../services/StageServices';
import { MediaContext } from '../context/MediaContext';
import MediaItems from '../components/MediaItems';

import './Forms.css';
import styles from './Table.module.css';
import { getProjectById } from '../services/ProjectServices';
import { getHousingTypologyById } from '../services/HousingTypologyServices';

function BlockForm() {
  const { id, stageId } = useParams(); // Obtenemos el ID del Block y el ID del Stage
  const [block, setBlock] = useState({
    code: '',
    name: '',
    description: '',
    buildPrice: '',
    balconyPrice: '',
    mezzaninePrice: '',
    tarracePrice: '',
    slug: '',
    mediaItems: []
  });
  const [error, setError] = useState(null);
  const [housingTypology, setHousingTypology] = useState(null);
  const {mediaItems, setMediaItems } = useContext(MediaContext);
  const [priceLists, setPriceLists] = useState([]);
  const [projectId, setProjectId] = useState(''); 
  const [projectSlug, setProjectSlug] = useState(''); 
  const [stageSlug, setStageSlug] = useState(''); 
  const [units, setUnits] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el slug de la etapa
        const stage = await getStageById(stageId);
        setStageSlug(stage.slug);
        setProjectId(stage.projectId);
        setPriceLists(stage.priceLists);
        const project = await getProjectById(stage.projectId);
        const htData = await getHousingTypologyById(stage.housingTypology);
        setHousingTypology(htData);
        setProjectSlug(project.slug);

        // Si estamos editando un bloque, obtener sus datos
        if (id) {
          const blockData = await getBlockById(id);
          setBlock(blockData);
          setUnits(blockData.units); 
          setMediaItems(blockData.mediaItems);
        } else {
          setMediaItems([]);
          setBlock((prevBlock) => ({ ...prevBlock, stageId: stageId, smlvPrice: htData.maxPrice }));
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, stageId, setMediaItems, updateCounter]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    const codeSlug = newCode.toLowerCase().replace(/ /g, '-');
    const newSlug = `${stageSlug}-${codeSlug}`;
    setBlock({ ...block, code: newCode, slug: newSlug });
  };

  const handleDeleteUnit = async (id) => {
    setError(null);
    setLoading(true);
    try {
      await deleteUnit(id);
      setUpdateCounter(updateCounter + 1);      
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      block.mediaItems = mediaItems;
      block.id = id;
      block.stageId = stageId;
      await saveBlock(block);  
    } catch (error) {
      setError(error.message);
    }
  }

  const handleSmlvPriceChange = (smlvPrice) => {
    const maxPrice = parseFloat(housingTypology.maxPrice);
    if (parseFloat(smlvPrice) > maxPrice) {
      smlvPrice = maxPrice;
    }
    setBlock({ ...block, smlvPrice: smlvPrice });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      block.mediaItems = mediaItems;
      if (id)
        block.id = id;
      block.stageId = stageId;
      await saveBlock(block);
      navigate(`/stages/edit/${projectId}/${stageId}`);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Editar Bloque' : 'Nuevo Bloque'}</h2>
        {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="block-code">Código del Bloque</label>
            <input
              type="text"
              id="block-code"
              value={block.code}
              onChange={handleCodeChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="block-name">Nombre</label>
            <input
              type="text"
              id="block-name"
              value={block.name}
              onChange={(e) => setBlock({ ...block, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="block-description">Descripción</label>
            <textarea
              value={block.description}
              id="block-description"
              onChange={(e) => setBlock({ ...block, description: e.target.value })}
              required
            />
          </div>

          {housingTypology && housingTypology.priceCriteria==='area' && (
          <div className="form-group">
            <label htmlFor="block-buildPrice">Lista de precios</label>
            <select
              id="block-buildPrice"
              value={block.priceList}
              onChange={(e) => setBlock({ ...block, priceList: e.target.value })}
              required
            >
              <option value="">Seleccione una lista de precios</option>
              {priceLists.map((priceList) => (
                <option key={priceList.id} value={priceList.id}>{priceList.description}</option>
              ))}
            </select>
          </div>
          )}

          {housingTypology && housingTypology.priceCriteria==='smlv' && (
          <>
          <div className="form-group">
            <label htmlFor="block-smlvPrice">Precio unidad (SMLV)</label>
            <input
              type="number"
              id="block-smlvPrice"
              value={block.smlvPrice}
              onChange={(e) => handleSmlvPriceChange(e.target.value) }
              required
            />
          </div>
          </>
          )}

          <div className="form-group">
            <label htmlFor="block-slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="block-slug"
              value={block.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Bloque'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to={`/stages/edit/${projectId}/${stageId}`} className="back-link">Regresar a Etapa</Link>
        </div>
      </div>

      {id && ( 
      <div className="stages-container">
        <MediaItems projectSlug={projectSlug} objectType='block' objectSlug={block.slug} />
        <br/>
        <button className="btn-submit" onClick={() => handleSave()}>Grabar</button>

        <h2>Unidades</h2>
        <button onClick={() => navigate(`/units/new/${id}`)}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" para agregar unidad */}
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Nombre de la Unidad</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {units.map((unit) => (
              <tr key={unit.id}>
                <td>{unit.name}</td>
                <td>
                  <button onClick={() => navigate(`/units/edit/${id}/${unit.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteUnit(unit.id) }>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      )}
    </div>
  );
}

export default BlockForm;
