// src/pages/UnitForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getUnitById, createUnit, updateUnit } from '../services/UnitServices'; // Asumimos que tienes estos servicios
import { getBlockById } from '../services/BlockServices';
import { getTypologiesByStageId } from '../services/TypologyServices'; // Para obtener la lista de tipologías
import './Forms.css';
import { getStageById } from '../services/StageServices';

function UnitForm() {
  const { id, blockId } = useParams(); 
  const [unit, setUnit] = useState({
    code: '',
    name: '',
    slug: '',
    buildArea: '',
    privateArea: '',
    terraceArea: '',
    terracePrivateArea: '',
    balconyArea: '',
    mezzanineArea: '',
    bedrooms: '',
    bathrooms: '',
    parkingSpaces: '',
    floor: '',
    status: 'available',
    typologyId: '',
    priceType: 'listPrice',
    fixedPrice: '',
    priceList: ''
  });
  const [blockSlug, setBlockSlug] = useState(''); // Guardamos el slug del bloque
  const [error, setError] = useState(null);
  const [priceLists, setPriceLists] = useState([]); 
  const [stageId, setStageId] = useState(''); 
  const [typologies, setTypologies] = useState([]); // Lista de tipologías
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Obtener el slug del bloque
        const block = await getBlockById(blockId);
        setBlockSlug(block.slug);
        setStageId(block.stageId);

        const stage = await getStageById(block.stageId);
        setPriceLists(stage.priceLists);

        // Obtener la lista de tipologías
        const typologyList = await getTypologiesByStageId(block.stageId);
        setTypologies(typologyList);

        // Si estamos editando una unidad, obtener sus datos
        if (id) {
          const unitData = await getUnitById(id);
          if (unitData) {
            if (!unitData.unit.priceType) {
              unitData.unit.priceType = 'listPrice';
            }
            setUnit(unitData.unit);
          } else {
            setError('No se encontró la unidad');
          }
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, blockId]);

  const handleCodeChange = (e) => {
    const newCode = e.target.value;
    const newSlug = `${blockSlug}-${newCode.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')}`;
    setUnit({ ...unit, code: newCode, slug: newSlug });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      if (id) {
        await updateUnit(id, unit);
      } else {
        await createUnit({ ...unit, blockId });
      }
      navigate(`/blocks/edit/${stageId}/${blockId}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="form-container">
      <h2>{id ? 'Editar Unidad' : 'Nueva Unidad'}</h2>
      {error && (
        <div>
          <div className='error-message'>
            Hubo un error: {error}
          </div>
          <br/>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="unit-code">Código de la Unidad</label>
          <input
            type="text"
            id="unit-code"
            value={unit.code}
            onChange={handleCodeChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-name">Nombre</label>
          <input
            type="text"
            id="unit-name"
            value={unit.name}
            onChange={(e) => setUnit({ ...unit, name: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-buildArea">Área de Construcción</label>
          <input
            type="number"
            id="unit-buildArea"
            value={unit.buildArea}
            onChange={(e) => setUnit({ ...unit, buildArea: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-privateArea">Área Privada</label>
          <input
            type="number"
            id="unit-privateArea"
            value={unit.privateArea}
            onChange={(e) => setUnit({ ...unit, privateArea: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-terraceArea">Área de Terraza</label>
          <input
            type="number"
            id="unit-terraceArea"
            value={unit.terraceArea}
            onChange={(e) => setUnit({ ...unit, terraceArea: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-terracePrivateArea">Área Privada de Terraza</label>
          <input
            type="number"
            id="unit-terracePrivateArea"
            value={unit.terracePrivateArea}
            onChange={(e) => setUnit({ ...unit, terracePrivateArea: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-balconyArea">Área de Balcón</label>
          <input
            type="number"
            id="unit-balconyArea"
            value={unit.balconyArea}
            onChange={(e) => setUnit({ ...unit, balconyArea: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-mezzanineArea">Área de Mezzanine</label>
          <input
            type="number"
            id="unit-mezzanineArea"
            value={unit.mezzanineArea}
            onChange={(e) => setUnit({ ...unit, mezzanineArea: e.target.value })}
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-bedrooms">Dormitorios</label>
          <input
            type="number"
            id="unit-bedrooms"
            value={unit.bedrooms}
            onChange={(e) => setUnit({ ...unit, bedrooms: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-bathrooms">Baños</label>
          <input
            type="number"
            id="unit-bathrooms"
            value={unit.bathrooms}
            onChange={(e) => setUnit({ ...unit, bathrooms: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-floor">Piso</label>
          <input
            type="number"
            id="unit-floor"
            value={unit.floor}
            onChange={(e) => setUnit({ ...unit, floor: e.target.value })}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="unit-status">Estado</label>
          <select
            value={unit.status}
            id="unit-status"
            onChange={(e) => setUnit({ ...unit, status: e.target.value })}
            required
          >
            <option value="available">Disponible</option>
            <option value="sold">Vendido</option>
            <option value="optioned">Opcionado</option>
            <option value="reserved">Reservado</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="unit-typologyId">Tipología</label>
          <select
            value={unit.typologyId}
            id="unit-typologyId"
            onChange={(e) => setUnit({ ...unit, typologyId: e.target.value })}
            required
          >
            <option value="">Seleccione una tipología</option>
            {typologies.map((typology) => (
              <option key={typology.id} value={typology.id}>
                {typology.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Tipo de Precio</label>
          <div>
            <label>
              <input
                type="radio"
                name="priceType"
                value="listPrice"
                checked={unit.priceType === 'listPrice'}
                onChange={(e) => setUnit({ ...unit, priceType: e.target.value })}
              />
              Precio de lista
            </label>
            <label>
              <input
                type="radio"
                name="priceType"
                value="fixedList"
                checked={unit.priceType === 'fixedList'}
                onChange={(e) => setUnit({ ...unit, priceType: e.target.value })}
              />
              Lista fija
            </label>
            <label>
              <input
                type="radio"
                name="priceType"
                value="fixedPrice"
                checked={unit.priceType === 'fixedPrice'}
                onChange={(e) => setUnit({ ...unit, priceType: e.target.value })}
              />
              Precio fijo
            </label>
          </div>
        </div>

        {unit.priceType === 'fixedPrice' && (
          <>
            <div className="form-group">
            <label htmlFor="unit-fixedPrice">Precio Fijo</label>
            <input
              type="number"
              id="unit-fixedPrice"
              value={unit.fixedPrice || ''}
              onChange={(e) => setUnit({ ...unit, fixedPrice: e.target.value })}
              required
            />
            </div>
            <div className="form-group">
              <label htmlFor="unit-viewDelta">Aplicar Delta de Vista</label>
              <input
                type="checkbox"
                id="unit-viewDelta"
                checked={unit.viewDelta || false}
                onChange={(e) => setUnit({ ...unit, viewDelta: e.target.checked })}
              />
            </div>
          </>
        )}

        {unit.priceType==='fixedList' && (
          <div className="form-group">
            <label htmlFor="unit-priceList">Lista de precios</label>
            <select
              id="unit-priceList"
              value={unit.priceList}
              onChange={(e) => setUnit({ ...unit, priceList: e.target.value })}
              required
            >
              <option value="">Seleccione una lista de precios</option>
              {priceLists.map((priceList) => (
                <option key={priceList.id} value={priceList.id}>{priceList.description}</option>
              ))}
            </select>
          </div>
        )}


        <div className="form-group">
          <label htmlFor="unit-slug">Slug (auto-generado)</label>
          <input
            type="text"
            id="unit-slug"
            value={unit.slug}
            readOnly
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-submit">{id ? 'Guardar Cambios' : 'Agregar Unidad'}</button>
        </div>
      </form>
      <div className="form-footer">
        <Link to={`/blocks/edit/${stageId}/${blockId}`} className="back-link">Regresar a Bloque</Link>
      </div>
    </div>
  );
}

export default UnitForm;
